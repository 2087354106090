<template>
    <div class="NewsList">
        <div class="tit">
            <VanImage width="auto" height="100%" lazy-load :src="require('@assets/images/goods/newstit.png')" />
            <a href="javascript:void(0);">MORE >></a>
        </div>
        <div class="List">
            <div class="item">
                <div class="pic">
                    <VanImage width="2.8rem" height="1.6rem" fit="cover" lazy-load src="" />
                </div>
                <div class="rcon">
                    <p class="Ellipses1">「广州」</p>
                    <p class="Ellipses1">512情人节品鉴活动，欢迎参加</p>
                </div>
            </div>
            <div class="item">
                <div class="pic">
                    <VanImage width="2.8rem" height="1.6rem" fit="cover" lazy-load src="" />
                </div>
                <div class="rcon">
                    <p class="Ellipses1">「广州」</p>
                    <p class="Ellipses1">512情人节品鉴活动，欢迎参加</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Image as VanImage } from 'vant';
export default {
    name: "NewsList",
    props: {
        goodsList: {
        type: Array,
        default: () => []
        }
    },
    data: function() {
        return {
        value: true
        };
    },
    components: {
        VanImage
    },
    mounted: function() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.NewsList{
    background: #d8d8d8;
    .tit{
        padding: 0.3rem 1rem;text-align: center;
        a{display:inline-block;width:2.6rem;background:#000;color:#fff;text-align: center;margin: 0 auto;border-radius: 0.1rem;line-height:0.6rem;margin-top:0.1rem;}
    }
    .List{
        padding-bottom: 0.2rem;
        .item{
            border-top: 1px solid #c9c9c9;padding: 0.1rem 0;margin: 0 0.2rem;overflow:hidden;
            .pic{float:left;}
            .rcon{
                padding-left: 3rem;padding-top: 0.2rem;
                p{font-size:0.28rem;line-height:0.5rem;}
            }
        }
        .item:first-child{border-top: none!important;}
    }
    
}
</style>