<template>
    <div class="Winery">
        <Empty description="暂无数据" />
    </div>
</template>
<script>
import { Col, Row, Empty } from 'vant';
import { Image as VanImage } from 'vant';
export default {
    name: "Winery",
    props: {
        goodsList: {
            type: Array,
            default: () => []
        }
    },
    data: function() {
        return {
            value: true
        };
    },
    components: {
        VanImage, Col, Row, Empty
    },
    mounted: function() {},
    methods: {}
}
</script>
<style scoped lang="scss">
.Winery{
    
}
</style>