<template>
    <div class="VideoList" v-if="VideoList.length > 0">
        <!-- <div class="tit" v-if="title">
            <VanImage width="4rem" height="auto" lazy-load :src="require('@assets/images/goods/videotit.png')" />
        </div> -->
        <div class="tit" >
            <!-- <VanImage width="4rem" height="auto" lazy-load :src="require('@assets/images/goods/Packtit.png')" /> -->
            <h1>产品视频
                <span>PRODUCT VIDEO</span>
            </h1>
        </div>
        <div class="con">
            <div class="videoplay">
                <video ref="videoPlayer" :src="videourl" controls v-if="videoshow"></video>
                <div class="poster" @click="toPlay" v-else>
                    <div class="mask">
                        <i class="iconfont iconbofang"></i>
                    </div>
                    <VanImage width="auto" height="4rem" fit="cover" lazy-load :src="poster" />
                </div>
            </div>
            <Tabs v-model="current" @change="onChange" :swipe-threshold="1">
                <tab :name="index" v-for="(item, index) in VideoList" :key="index">
                    <template #title>
                        <div class="Thumbpic">
                            <VanImage width="auto" height="1.4rem" fit="cover" lazy-load :src="item.images" />
                        </div>
                        <div class="Thumbname Ellipses1">
                            {{item.name}}
                        </div>
                    </template>
                </tab>
            </Tabs>
        </div>
    </div>
</template>
<script>
import { Tab, Tabs } from 'vant';
import { Image as VanImage } from 'vant';
export default {
    name: "NewsList",
    props: {
        goodsList: {
            type: Array,
            default: () => []
        },
        VideoList: {
            type: Array,
            default: () => []
        },
        poster: {
            type: String,
            value: '',
        },
        videourl: {
            type: String,
            value: '',
        },
        title: {
            type: Boolean,
            value: true,
        },
        heights:{
            type: Number,
            value:4
        }
    },
    data: function() {
        return {
            current: 0,
            videoshow: false,
            VideoList: [],
        };
    },
    components: {
        VanImage, Tab, Tabs
    },
    mounted: function() {},
    methods: {
        onChange(index) {
            this.videoshow=false;
            this.current=index;

            this.poster=this.VideoList[index].images;
            this.videourl=this.VideoList[index].video;
        },
        toPlay(){
            this.videoshow=true;
            const that = this;
            setTimeout(() => {
                that.$refs.videoPlayer.play();
            }, 100);
        },
    }
}
</script>
<style scoped lang="scss">
::v-deep {
    .van-tab{padding: 0 0.05rem;}
    .van-tabs__wrap {
        height: 2rem;margin:0.1rem 0;
    }
    .van-tabs__line{display:none;}
    .van-tab--active{
        .Thumbpic{border: 1px solid #333!important;}
    }
}
.VideoList{
    background: #fff;
    // .tit{
    //     padding: 0.3rem 0.3rem 0.2rem;background: #b8b2b2;
    // }
    .tit{
        color: #545454;
        font-size: 0.35rem;
        padding: 0.6rem 0.3rem;
        background: #e1e1e1;
        span{
            padding-left: 0.1rem;
        }
    }
    .videoplay{
        .poster{
            width:100%;height: 4rem;background:#000;position: relative;
            .mask{background: rgb(0, 0, 0, 0.3);top:0;left: 0;right: 0;bottom:0;position: absolute;display: flex;align-items: center;justify-content: center;}
            i{color: #ffffff;font-size: 1rem;}
        }
        video{width:100%;height: 4rem;background:#000;}
    }
    .Thumbpic{
        border: 1px solid #eee;border-radius: 0.1rem;overflow: hidden;height: 1.4rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
    .Thumbname{text-align: center;font-size: 0.2rem;line-height: 0.5rem;width: 2rem;}
}
</style>