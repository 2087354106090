<template>
    <div class="picture">
        <div class="hdpic">
            <VanImage width="100%" height="100%" lazy-load :src="require('@assets/images/test/test03.jpg')" />
        </div>
        <div class="hdpic">
            <VanImage width="100%" height="100%" lazy-load :src="require('@assets/images/goods/foods.jpg')" />
        </div>
        <div class="foods">
            <div class="foodslist">
                <div class="foodsitem">
                    <VanImage width="100%" height="100%" lazy-load src="" />
                </div>
                <div class="foodsitem">
                    <VanImage width="100%" height="100%" lazy-load src="" />
                </div>
                <div class="foodsitem">
                    <VanImage width="100%" height="100%" lazy-load src="" />
                </div>
                <div class="foodsitem">
                    <VanImage width="100%" height="100%" lazy-load src="" />
                </div>
                <div class="foodsitem">
                    <VanImage width="100%" height="100%" lazy-load src="" />
                </div>
                <div class="foodsitem">
                    <VanImage width="100%" height="100%" lazy-load src="" />
                </div>
                <div class="foodsitem">
                    <VanImage width="100%" height="100%" lazy-load src="" />
                </div>
                <div class="foodsitem">
                    <VanImage width="100%" height="100%" lazy-load src="" />
                </div>
            </div>
            <div class="foodsmore" @click="goPages('/Tasting/goods/piclist?tab=1')">更多搭配 >></div>
        </div>
        <!--素材&广告图-->
        <div class="itemtit acea-row row-between-wrapper" style="height: 1rem;">
            <div class="name">素材&广告图</div>
            <div @click="goPages('/Tasting/goods/piclist?tab=0')">
                全部60张
                <Icon name="arrow" />
            </div>
        </div>
        <div class="itempic">
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
        </div>
        <!--视频-->
        <div class="itemtit acea-row row-between-wrapper" style="height: 1rem;">
            <div class="name">视频</div>
            <div>
                全部60个
                <Icon name="arrow" />
            </div>
        </div>
        <div class="itempic" style="height:1.8rem;">
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
        </div>
        <!--进口文件与证书-->
        <div class="itemtit acea-row row-between-wrapper">
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load :src="require('@assets/images/goods/tit04.jpg')" />
            </div>
            <div @click="goPages('/Tasting/goods/piclist?tab=2')">
                全部60张
                <Icon name="arrow" />
            </div>
        </div>
        <div class="itempic" style="height:1.8rem;">
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
        </div>
        <!--运输与仓储-->
        <div class="itemtit acea-row row-between-wrapper">
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load :src="require('@assets/images/goods/tit05.jpg')" />
            </div>
            <div @click="goPages('/Tasting/goods/piclist?tab=3')">
                全部60张
                <Icon name="arrow" />
            </div>
        </div>
        <div class="itempic" style="height:1.8rem;">
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic" style="width:2.6rem;">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
        </div>
        <!--包装与礼盒-->
        <div class="itemtit acea-row row-between-wrapper">
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load :src="require('@assets/images/goods/tit06.jpg')" />
            </div>
            <div @click="goPages('/Tasting/goods/piclist?tab=4')">
                全部60张
                <Icon name="arrow" />
            </div>
        </div>
        <div class="itempic">
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
            <div class="pic">
                <VanImage width="100%" height="100%" lazy-load fit="cover" src="" />
            </div>
        </div>
    </div>
</template>
<script>
import { Col, Row, Icon } from 'vant';
import { Image as VanImage } from 'vant';
export default {
    name: "picture",
    props: {
        goodsList: {
            type: Array,
            default: () => []
        }
    },
    data: function() {
        return {
            value: true
        };
    },
    components: {
        VanImage, Col, Row, Icon
    },
    mounted: function() {},
    methods: {
        goPages: function(url) {
            this.$router.push({ path: url });
        },
    }
}
</script>
<style scoped lang="scss">
.picture{
    .hdpic{
        height: auto;
    }
    .foods{
        background: #fff;
        .foodslist{
            overflow:hidden;
            .foodsitem{
                height: 2rem;width: 50%;float:left;padding: 0.1rem;
            }
        }
        .foodsmore{
            text-align: center;line-height: 0.8rem;font-size: 0.24rem;
        }
    }
    .itemtit{
        background: #d8d8d8;margin-top: 0.2rem;height:1.2rem;padding: 0 0.1rem 0 0.2rem;font-size: 0.24rem;color:#999;overflow:hidden;
        i{float:right;font-size: 0.35rem;}
        .name{font-size: 0.32rem;color:#333;}
        .pic{width: 4rem;}
    }
    .itempic{
        display: inline-block;overflow: scroll;width: 100%;white-space: nowrap;background:#fff;height: 2.6rem;padding: 0.1rem 0;
        .pic{
            display: inline-block;width: 2rem;height: 100%;margin: 0 0.1rem;
        }
    }
}
</style>